import React from 'react'
import './module.css'

export default function MessageText({ text }) {

    return (
        <div className="message">
            <div className="messageText">{text}</div>
        </div>
    )
}