// LinkButton.jsx
import React from 'react';
import { Icon } from '@iconify/react';
import './module.css';

export default function LinkButton({ icon, nameLink, handleClick, isLoading, isMessage }) {
  

  return (
    <div className='containerRouteButton' onClick={handleClick}>
      <div className='containerActiveSidebar'>
        <Icon icon={icon} style={{ width: '24px', height: '24px' }} />
        <p className='nameLink'>{nameLink}</p>
      </div>
      {isLoading && <Icon icon="line-md:downloading-loop" style={{ width: '24px', height: '24px', color: '#0088ff' }} />}
      {isMessage && <Icon icon='marketeq:notification-alt' className='messageIcon' width="22px" height="22px"  style={{color: '#0088ff'}}/> }
    </div>
  );
}
