import React from "react";
import "./module.css"
import DeviceState from "../../../../../components/DeviceState/DeviceState";


export default function OpenBasicDevice({ data }) {
    function formatDateFull(dateString) {
        let date = new Date(dateString);
        let options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'};
        return date.toLocaleString('ru-RU', options).replace(',', ' ');
    }

    return (
        <div className="openBasicDeviceContainer">
            <div className="StateBasicDevice">
                <p>Состояние</p>
                <DeviceState battery={data?.battery}/>
            </div>
            <div className="startedDateBasicDevice">
                <p>Последнее обновление</p>
                {data?.last_update ? formatDateFull(data?.last_update) : 'Не установлено'}
            </div>
        </div>
    );
}