import { useLocation } from 'react-router-dom';

export default function Receipt() {
    const location = useLocation();
    const { base64, name, date } = location.state || {};
    
    if (!base64) {
        return <p>Файл не найден</p>;
    }

    // Создаем URL для изображения из base64
    const fileURL = `data:image/jpeg;base64,${base64}`;

    return (
        <div className="receiptViewer">
            <h1>{name}</h1>
            <img
                src={fileURL}
                alt={name}
                style={{ width: '100%', maxHeight: '80vh', objectFit: 'contain' }}
            />
            <a href={fileURL} download={name || 'изображение.jpg'}>Скачать изображение</a>
        </div>
    );
}
