import './openchat.css';
import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';

export default function OpenChat({ dispute, payment, setDispute, close }) {

    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    
    useEffect(() => {
        fetchMessages();
        const interval = setInterval(() => {
            fetchMessages();
        }, 3000);

        return () => {
            clearInterval(interval);
        }
    }, []);

    const fetchMessages = async () => {
        const response = await fetch('/api/pay/chat/' + payment.payment.id);
        if (response.ok) {
            const data = await response.json();
            setMessages(data);
        }
    }

    function formatMessageDate(dateString) {
        const date = new Date(dateString);
        const now = new Date();
        const diffMs = now - date; // разница в миллисекундах
        const diffSec = Math.floor(diffMs / 1000);
        const diffMin = Math.floor(diffSec / 60);
        const diffHours = Math.floor(diffMin / 60);
        const diffDays = Math.floor(diffHours / 24);

        if (diffMin < 1) {
            return 'Только что';
        } else if (diffMin < 60) {
            return `${diffMin} мин. назад`;
        } else if (diffHours < 24) {
            return `${diffHours} час${getHoursSuffix(diffHours)} назад`;
        } else if (diffDays === 1) {
            return `Вчера в ${format(date, 'HH:mm', { locale: ru })}`;
        } else {
            return format(date, 'dd MMMM yyyy в HH:mm', { locale: ru });
        }
    }

    function getHoursSuffix(hours) {
        if (hours === 1 || (hours > 20 && hours % 10 === 1)) return '';
        if (hours >= 2 && hours <= 4 || (hours > 20 && [2, 3, 4].includes(hours % 10))) return 'а';
        return 'ов';
    }

    const sendMessage = async () => {
        setNewMessage('');
        const message = {
            type: 'message',
            text: newMessage,
            from: 'support'
        };
        const response = await fetch('/api/pay/chat/' + payment.payment.id, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(message)
        })
        if (response.ok) {
            const data = await response.json()
            setMessages(data);
        }

    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // чтобы избежать перехода на новую строку
            sendMessage();
        }
    };

    const handleFileClick = (file) => {
        const [fileType, base64Content] = file.split(';base64,');

        const fileWindow = window.open('', '_blank');

        if (fileType.startsWith('data:image/')) {
            fileWindow.document.write(`
            <html>
            <head><title>fastpay</title></head>
            <body>
                <img src="${file}" alt="Изображение" style="display: flex; width: auto; height: 100%; margin: 0 auto;" />
            </body>
            </html>
        `);
        }

        else if (fileType === 'data:application/pdf') {
            fileWindow.document.write(`
            <html>
            <head><title>fastpay</title></head>
            <body>
                <iframe src="${file}" style="width:100%; height:100%;" frameborder="0"></iframe>
            </body>
            </html>
        `);
        }
    };

    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = async () => {
                const base64File = reader.result;
                const bodyRequest = {
                    type: 'file',
                    file_name: file.name,
                    file_data: base64File
                }

                const response = await fetch('/api/pay/chat/' + payment.payment.id, {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(bodyRequest)
                })
                if (response.ok) {
                    const data = await response.json();
                    setMessages(data);
                }
            }
            reader.readAsDataURL(file);
        }
    }

    return (
        <div className='chat'>
            <div className='chatMessages'>
                {messages.map((msg) => (
                    <div className={`chatMessageBlock ${msg.message_from}`}>
                        {msg.content_type === 'message' ? (
                            <div className='chatMessage'>
                                <p>{msg.message_text}</p>
                                <span>{formatMessageDate(msg.message_date)}</span>
                            </div>
                        ) : (
                            <div className="chatFile">
                                <div className="chatFileName" onClick={() => handleFileClick(msg.file)}>
                                    <File />
                                    {msg.file_name}
                                </div>
                                <span>{formatMessageDate(msg.message_date)}</span>

                            </div>
                        )}
                    </div>
                ))}
            </div>
            <div className='chatSend'>
                <input
                    type='text'
                    placeholder='Введите Ваше сообщение'
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    onKeyDown={handleKeyDown}
                />
                <input
                    type='file'
                    id='fileInput'
                    accept='.jpg, .png, .pdf'
                    onChange={handleFileChange}
                />
                <label htmlFor="fileInput">
                    <File />
                </label>
            </div>
        </div>
    );

}



function File() {
    return (
        <svg width="22" height="27" viewBox="0 0 22 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.6663 9.41984C21.6526 9.29733 21.6257 9.17665 21.5863 9.05984V8.93984C21.5237 8.8019 21.438 8.67565 21.333 8.5665L13.333 0.566504C13.2239 0.461465 13.0976 0.375794 12.9597 0.31317H12.8397L12.413 0.166504H4.33301C3.27214 0.166504 2.25473 0.587931 1.50458 1.33808C0.754435 2.08822 0.333008 3.10564 0.333008 4.1665V22.8332C0.333008 23.894 0.754435 24.9115 1.50458 25.6616C2.25473 26.4117 3.27214 26.8332 4.33301 26.8332H17.6663C18.7272 26.8332 19.7446 26.4117 20.4948 25.6616C21.2449 24.9115 21.6663 23.894 21.6663 22.8332V9.41984ZM13.6663 4.71317L17.1197 8.1665H13.6663V4.71317ZM18.9997 22.8332C18.9997 23.1868 18.8592 23.5259 18.6092 23.776C18.3591 24.026 18.02 24.1665 17.6663 24.1665H4.33301C3.97939 24.1665 3.64025 24.026 3.3902 23.776C3.14015 23.5259 2.99967 23.1868 2.99967 22.8332V4.1665C2.99967 3.81288 3.14015 3.47374 3.3902 3.2237C3.64025 2.97365 3.97939 2.83317 4.33301 2.83317H10.9997V9.49984C10.9997 9.85346 11.1402 10.1926 11.3902 10.4426C11.6402 10.6927 11.9794 10.8332 12.333 10.8332H18.9997V22.8332Z" fill="url(#paint0_linear_44_3250)" />
            <defs>
                <linearGradient id="paint0_linear_44_3250" x1="10.9997" y1="0.166504" x2="9.65669" y2="26.7991" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#F700FF" />
                    <stop offset="1" stop-color="#F3C2F9" />
                </linearGradient>
            </defs>
        </svg>
    );
}
