import React from 'react';
import { useTable } from 'react-table';
import { useNavigate, useLocation } from 'react-router-dom';
import './module.css';

// Function to convert style string to style object
const parseStyle = (styleString) => {
  if (!styleString) return {};

  const styleObject = {};
  const styleArray = styleString.split(';').filter(Boolean);
  styleArray.forEach(style => {
    const [key, value] = style.split(':').map(s => s.trim());
    if (key && value) {
      const camelCaseKey = key.replace(/-([a-z])/g, g => g[1].toUpperCase());
      styleObject[camelCaseKey] = value;
    }
  });
  return styleObject;
};

function Table({ headers, rows, rowHover }) {
  const navigate = useNavigate();
  const location = useLocation();

  // Convert rows to table data
  const data = React.useMemo(() => {
    return (rows || []).map(row => {
      if (!Array.isArray(row) || row.length < 2) {
        console.error('Row data is invalid:', row);
        return {};
      }

      const [id, cells] = row;
      if (!Array.isArray(cells)) {
        console.error('Row cells are not an array:', cells);
        return {};
      }

      return cells.reduce((acc, cell, index) => {
        const columnId = headers[index]?.id || headers[index];
        acc[columnId] = cell;
        return acc;
      }, { id }); // Include id in the row data
    });
  }, [rows, headers]);

  // Define columns with components and styles
  const columns = React.useMemo(() => {
    return headers.map(header => {
      const id = header?.id || header;
      const style = header?.style ? parseStyle(header.style) : {};

      return {
        Header: header?.label || header,
        accessor: id,
        Cell: ({ value }) => value,
        style
      };
    });
  }, [headers]);

  const { getTableProps, getTableBodyProps, headerGroups, rows: tableRows, prepareRow } = useTable({
    columns,
    data
  });

  const handleRowClick = (rowId, e) => {
    // Prevent navigation if the event target is a checkbox or button
    if (e.target.closest('input[type="checkbox"]') || e.target.closest('button')) {
      return;
    }
    
    const basePath = 
      location.pathname.startsWith('/dispute') ? '/dispute' :
      location.pathname.startsWith('/deals') ? '/deals' :
      location.pathname.startsWith('/requisite') ? '/requisite' :
      location.pathname.startsWith('/device') ? '/device' :
      '';

    if (basePath) {
      navigate(`${basePath}/${rowId}`);
    } else {
      console.error('Unknown path:', location.pathname);
    }
  };

  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => {
          const { key, ...restHeaderGroupProps } = headerGroup.getHeaderGroupProps();  // Извлекаем key
  
          return (
            <tr key={key} {...restHeaderGroupProps}>
              {headerGroup.headers.map(column => {
                const { key, ...restColumnProps } = column.getHeaderProps();  // Извлекаем key
  
                return (
                  <th key={key} {...restColumnProps} style={column.style}>
                    {column.render('Header')}
                  </th>
                );
              })}
            </tr>
          );
        })}
      </thead>
      <tbody {...getTableBodyProps()}>
        {tableRows.map(row => {
          prepareRow(row);
          const { key, ...restRowProps } = row.getRowProps();  // Извлекаем key
  
          return (
            <tr
              key={key}
              {...restRowProps}
              className={rowHover ? 'rowHover' : ''}
              onClick={(e) => handleRowClick(row.original.id, e)}
            >
              {row.cells.map(cell => {
                const { key, ...restCellProps } = cell.getCellProps();  // Извлекаем key
  
                return (
                  <td key={key} {...restCellProps}>
                    {cell.render('Cell')}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
  
}

export default Table;
