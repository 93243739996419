import React, { useState, useEffect } from 'react';
import DateComponent from '../../../components/DateComponent/DateComponent';
import Table from '../../../components/Table/Table';
import SearchFilter from '../../../components/SearchFilter/SearchFilter';
import './module.css';
import { formatDate, MAX_DATE, MIN_DATE, generateRows } from '../../../Data';

export default function Deals({ data, setPageLoading }) {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [searchData, setSearchData] = useState('');
    const [filterSum, setFilterSum] = useState('');
    // const [responseData, setResponseData] = useState([]);
    const [filterStatus, setFilterStatus] = useState('');
    const officeId = localStorage.getItem('office_token');
    const genRows = generateRows(filteredData, 'deals');

    useEffect(() => {
        const fetchData = async () => {
            if ((startDate && !endDate) || (!startDate && endDate)) {
                console.warn("Даты не выбраны. Запрос не будет выполнен.");
                return;
            }

            // const sanitizedSearchData = searchData.replace(/\s+/g, ''); // Удаляем все пробелы из номера карты
            // if (sanitizedSearchData.length > 0 && sanitizedSearchData.length !== 16) {
            //     console.warn("Номер карты должен содержать 16 символов.");
            //     return;
            // }

            setPageLoading(true);
            try {
                let url = `/api/payments?office_id=${officeId}&date_st=${formatDate(startDate)}&date_en=${formatDate(endDate)}`;
                if (filterStatus) {
                    url += `&status=${filterStatus}`;
                }
                if (searchData) {
                    url = `/api/payment/${searchData}`;
                }
                if (filterSum) {
                    url += `&amount=${filterSum}`;
                }

                // console.log(filterStatus);
                // console.log(searchData);
                const response = await fetch(url);
                if (response.ok) {
                    const dynamicData = await response.json();
                    // setResponseData(dynamicData);
                    console.log(dynamicData);

                    let formattedData = [];

                    if (Array.isArray(dynamicData.payments)) {

                        // Обработка данных как массива объектов
                        formattedData = dynamicData.payments.map(item => ({
                            transactionId: item.payment.id,
                            eventTime: item.date.create,
                            transactionState: ['AC', 'FF', 'CF', 'CL'].includes(item.payment.status),
                            transactionSum: item.payment.amount.usdt,
                            transactionSumIsRub: item.payment.amount.rub,
                            transactionBankCard: item.payment.card.number,
                            transactionBankName: item.payment.type,
                            transactionOwnerName: item.payment.device.owner,
                            transactionUniqDevice: item.payment.device.id
                        }));
                    } else if (dynamicData.id) {
                        // Обработка данных как объекта
                        formattedData = [
                            {
                                transactionId: dynamicData.id,
                                eventTime: dynamicData.create,
                                transactionState: dynamicData.card.condition,
                                transactionSum: dynamicData.amount_usdt,
                                transactionSumIsRub: dynamicData.amount,
                                transactionBankCard: dynamicData.card.number,
                                transactionBankName: dynamicData.card.bank,
                                transactionOwnerName: dynamicData.card.name,
                                transactionUniqDevice: dynamicData.device.token
                            }
                        ];

                    } else {
                        console.error('Непредвиденный формат данных:', dynamicData);
                    }

                    setFilteredData(formattedData);
                } else {
                    console.error('Ошибка при загрузке данных', response.status);
                }
            } catch (error) {
                console.error('Ошибка:', error);
            } finally {
                setPageLoading(false);
            }
        };

        fetchData();
    }, [officeId, startDate, endDate, filterStatus, filterSum, searchData, data]);

    const handleChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };

    const handleSelectAll = () => {
        setStartDate(MIN_DATE);
        setEndDate(MAX_DATE);
    };

    const handleFilterChange = (searchTerm, filterStatus, filterSum) => {
        setSearchData(searchTerm.trim());
        setFilterStatus(filterStatus.trim() === 'completed' ? 'non-active' : (filterStatus.trim() === 'uncompleted' ? 'active' : ''));
        setFilterSum(filterSum.trim());
    };

    return (
        <div className='deals'>
            <div className="header">
                <h1>Сделки</h1>
            </div>
            <div className='headerData'>
                <SearchFilter
                    data={data.tables.deals.searchInput}
                    onFilterChange={handleFilterChange}
                    showSumFilter={true}
                />
                <DateComponent
                    startDate={startDate}
                    endDate={endDate}
                    handleChange={handleChange}
                    handleSelectAll={handleSelectAll}
                />
            </div>
            <Table
                headers={data.tables.deals.headers}
                rows={genRows.map(row => row.components)}
                rowHover={'rowHover'}
            />
        </div>
    );
}
