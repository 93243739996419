import React, { useState, useEffect } from 'react';
import { Icon } from '@iconify/react/dist/iconify.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Verification({ card, amount, close, setVerif, dop, dopType }) {

    const [verification, setVerification] = useState(false);
    const [veryfyType, setVeryfyType] = useState('');
    const [verifCard, setVerifCard] = useState(false);
    const [verifPhone, setVerifPhone] = useState(false);
    const [verifPayment, setVerifPayment] = useState(false);
    const [intervalId, setIntervalId] = useState(null);
    const [loading, setLoading] = useState(true);
    const [bank, setBank] = useState({})

    useEffect(() => {
        const fetchBank = async () => {
            const response = await fetch('/api/banks');
            if (response.ok) {
                const data = await response.json();
                const selected = data.find(bank => bank.name === card.bank);
                setBank(selected);
                setLoading(false);
            }
        }

        fetchBank();
    }, [])

    const fetchVerif = async (type, cardId) => {
        try {
            const response = await fetch(`/api/detail/${cardId}/verif`);
            if (response.ok) {
                const data = await response.json();
                if (type === 'card') {
                    if (data.verif_card) {
                        setVerifCard(data.verif_card)
                        stopVerification();
                    }
                }
                if (type === 'phone') {
                    if (data.verif_phone) {
                        setVerifPhone(data.verif_phone)
                        stopVerification();
                    }
                }
                if (type === 'paylink') {
                    if (data.verif_paylink) {
                        setVerifPayment(data.verif_payment)
                        stopVerification();
                    }
                }
                if (dop) {
                    setVerifCard(data.verif);
                }
                if (data.verif) {
                    setVerif(data.verif);
                }
            } else {
                console.log('Ошибка')
            }
        } catch (error) {
            console.log('Даже не зашел')
        } finally {
            console.log('Зашел')
        }
    }

    const handleVerify = (type, id) => {
        setVerification(true);
        const intId = setInterval(() => {
            fetchVerif(type, id)
        }, 5000);
        setIntervalId(intId)
        setVeryfyType(type)
    }

    const stopVerification = () => {
        clearInterval(intervalId); // Очищаем интервал
    };

    useEffect(() => {
        return () => {
            if (intervalId) clearInterval(intervalId);
        };
    }, [intervalId]);

    const handleCopy = (text) => {
        const textToCopy = text;

        const input = document.createElement('input');
        input.value = textToCopy;
        document.body.appendChild(input);
        input.select();
        document.execCommand('copy');
        document.body.removeChild(input);

        toast.success(text === '0000 0000 0000 0000' ? 'Номер карты скопирован в буфер обмена!' : text === '+7 777 777 77 77' ? 'Номер телефона скопирован в буфер обмена!' : '');
    };
    console.log(card);
    console.log(bank);

    return (
        <>
            {!verification && (
                <div className="modal">
                    <div className="modalHeader" style={{ alignItems: 'center' }}>
                        <p>Верифицируйте реквизиты</p>
                    </div>
                    <div className="content">
                        <div className='inputData'>
                            <label>Банк</label>
                            <div className="bankInfo">
                                {card.bank_full}
                            </div>
                        </div>
                        {loading ? (
                            <div className="contentLoading" style={{ width: "404px" }}>
                                <Icon icon="line-md:downloading-loop" style={{ width: '24px', height: '24px', color: '#0088ff' }} />
                            </div>
                        ) : (
                            <div >
                                {bank?.card_number && !dop && (
                                    <div className='inputData'>
                                        <label>Основная карта</label>
                                        <div className="inputDataCard" style={{ backgroundColor: 'rgba(239, 239, 239, 0.3)' }}>
                                            <Icon icon='ion:card-outline' width="50px" height="20px" />
                                            <input
                                                type="text"
                                                value={card.number}
                                                maxLength="19"
                                                disabled
                                            />
                                            <button className='verificationbtn' disabled={verifCard} onClick={() => handleVerify('card', card.id)}>{verifCard ? 'Подтверждено' : 'Верифицировать'}</button>
                                        </div>
                                    </div>
                                )}
                                {!bank?.card_number && bank?.payment_number && !dop && (
                                    <div className='inputData'>
                                        <label>Основной счет</label>
                                        <div className="inputDataCard" style={{ backgroundColor: 'rgba(239, 239, 239, 0.3)' }}>
                                            <Icon icon='ion:card-outline' width="50px" height="20px" />
                                            <input
                                                type="text"
                                                value={card.payment_number}
                                                maxLength="24"
                                                disabled
                                            />
                                            <button className='verificationbtn' disabled={verifPayment} onClick={() => handleVerify('payment', card.id)}>{verifPayment ? 'Подтверждено' : 'Верифицировать'}</button>
                                        </div>
                                    </div>
                                )}
                                <div className="cardContacts">
                                    {bank?.phone_number && !dop && (
                                        <div className='inputData'>
                                            <label>Телефон</label>
                                            <div className="inputDataCard" style={{ backgroundColor: 'rgba(239, 239, 239, 0.3)' }}>
                                                <Icon icon="ph:sim-card-light" width="50px" height="24px" />
                                                <input
                                                    type="text"
                                                    value={card.phone}
                                                    placeholder="Введите номер"
                                                    maxLength="18"
                                                    disabled
                                                />
                                                <button className='verificationbtn' disabled={verifPhone} onClick={() => handleVerify('phone', card.id)}>{verifPhone ? 'Подтверждено' : 'Верифицировать'}</button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                {dop && (
                                    <div className='inputData'>
                                        <label>Реквизит</label>
                                        <div className="inputDataCard" style={{ backgroundColor: 'rgba(239, 239, 239, 0.3)' }}>
                                            <Icon icon='ion:card-outline' width="50px" height="20px" />
                                            <input
                                                type="text"
                                                value={card.number}
                                                maxLength="24"
                                                disabled
                                            />
                                            <button className='verificationbtn' disabled={verifCard} onClick={() => handleVerify('payment', card.id)}>{verifCard ? 'Подтверждено' : 'Верифицировать'}</button>
                                        </div>
                                    </div>
                                )}

                            </div>
                        )}
                    </div>
                    <div className="modalActions">
                        <button className="closeModal" onClick={close}>Вернуться позже</button>
                        {!dop ? (
                            <button className="addReqBtn" disabled={!(verifCard && verifPhone && verifPayment)} onClick={close}>Готово</button>
                        ) : (
                            <button className="addReqBtn" disabled={!(verifCard)} onClick={close}>Готово</button>
                        )}
                    </div>
                </div>)}
            {verification &&
                <div className="modal">
                    {!dop ? (
                        <div className="modalHeader">Переведите {amount} ₽ по номеру {veryfyType === 'card' ? 'карты' : veryfyType === 'phone' ? 'телефона' : 'счета'}</div>
                    ) : (
                        <div className="modalHeader">Переведите {amount} ₽</div>
                    )}
                    <div className="content">
                        <p className='description'>Совершите перевод с другого банковского аккаунта.<br></br>Так мы верифицируем данный реквизит.</p>
                        {!dop ? (
                            <div className="inputData">
                                <label htmlFor="cardNumber">{veryfyType === 'card' ? 'Номер карты' : veryfyType === 'phone' ? 'Телефон' : 'Номер счета'}</label>
                                <div className='cardTo'>
                                    <p onClick={() => handleCopy(veryfyType === 'card' ? card.number : veryfyType === 'phone' ? card.phone : card.payment_number)}>{veryfyType === 'card' ? card.number : veryfyType === 'phone' ? card.phone : card.payment_number}</p>
                                    <Icon icon="mi:copy" className='copyIcon' onClick={() => handleCopy(veryfyType === 'card' ? card.cardNumber : veryfyType === 'phone' ? card.phone : '')} />
                                </div>
                            </div>
                        ) : (
                            <div className="inputData">
                                <label htmlFor="cardNumber">Реквизит</label>
                                <div className='cardTo'>
                                    <p onClick={() => handleCopy(card.number)}>{card.number}</p>
                                    <Icon icon="mi:copy" className='copyIcon' onClick={() => handleCopy(veryfyType === 'card' ? card.cardNumber : veryfyType === 'phone' ? card.phone : '')} />
                                </div>
                            </div>
                        )}
                        <div className='inputData'>
                            <label htmlFor="sum">Сумма перевода</label>
                            <p>{amount} ₽</p>
                        </div>
                        <div className='inputData'>
                            <label htmlFor="fullname">ФИО получателя</label>
                            <p>{card.name}</p>
                        </div>
                        <div className='inputData'>
                            <label htmlFor="bank">Банк</label>
                            <p>{card.bank_full}</p>
                        </div>
                        <ToastContainer />
                        <div className="warning" style={{ alignItems: 'center' }}>
                            <Icon icon="mingcute:warning-line" width="24px" height="24px" style={{ color: '#707070' }} />
                            <div className="warningText">
                                <p>Совершите перевод одним платежем.<br></br> Не оставляйте комментарий к переводу
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="modalActions" style={{ flexDirection: 'column' }}>
                        <button className="addReqBtn" onClick={() => setVerification(false)}>Я перевел</button>
                        <button className="closeModal stop" onClick={() => setVerification(false)}>Отменить верификацию</button>
                    </div>
                </div>
            }
        </>
    )
}