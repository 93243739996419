import React from 'react'
import './module.css'

export default function Balance({ balance }) {
    function formatNumber(number) {
        return number.toLocaleString('ru-RU', { maximumFractionDigits: 0 });
      }
  return (
    <div className='balance'>
        {formatNumber(balance)} RUB
    </div>
  )
}
