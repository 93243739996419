import React from 'react'
import './module.css'

export default function MessageDate({ eventTime }) {

    return (
        <div className="message">
            <div className="messageTime">{eventTime}</div>
        </div>
    )
}
