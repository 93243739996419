import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react/dist/iconify.js';
import './module.css';
// import Cookies from 'js-cookie';

export default function Login({ onLogin }) {
    const [officeToken, setOfficeToken] = useState('');
    const [codeVerify, setCodeVerify] = useState('');
    const [showCodeVerify, setShowCodeVerify] = useState(false);
    const [viewCodeMessage, setViewCodeMessage] = useState('tg'); // Инициализируем с дефолтным значением
    const navigate = useNavigate();

    const handleLoginClick = async () => {
        if (!showCodeVerify) {
            await requestOfficeToken();
        } else {
            await requestVerificationCode();
        }
    };

    const requestOfficeToken = async () => {
        if (!officeToken) {
            alert('Пожалуйста, введите токен офиса');
            return;
        }

        try {
            const ip = await getIPAddress();
            localStorage.setItem('office_token', officeToken.replace("_SUPPORT", ""));
            localStorage.setItem('office_type', officeToken.includes("_SUPPORT") ? "support" : "trader");
            const response = await fetch(`/api/login?office_id=${officeToken.replace("_SUPPORT", "")}&ip=${ip}`, {
                method: 'GET',
            });
            if (response.ok) {
                setShowCodeVerify(true);
            } else {
                alert('Неверный токен офиса');
            }
        } catch (err) {
            console.error('Ошибка при запросе токена офиса:', err);
            alert('Ошибка при запросе токена офиса');
        }
    };

    const requestVerificationCode = async () => {
        if (!codeVerify) {
            alert('Пожалуйста, введите код подтверждения');
            return;
        }

        try {
            console.log(viewCodeMessage);
            const response = await fetch('/api/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    office_id: officeToken.replace("_SUPPORT", ""),
                    code: codeVerify,
                    type: viewCodeMessage,
                }),
            });

            if (response.ok) {
                // const data = await response.json();
                onLogin();
                navigate('/');
            } else {
                alert('Неверный код подтверждения');
            }
        } catch (err) {
            console.error('Ошибка при запросе кода подтверждения:', err);
            alert('Ошибка при запросе кода подтверждения');
        }
    };

    const handleOfficeTokenChange = (e) => {
        setOfficeToken(e.target.value);
    };

    const handleCodeVerifyChange = (e) => {
        setCodeVerify(e.target.value);
    };

    const handleViewCodeMessage = (e) => {
        setViewCodeMessage(e.target.value);
    };

    const getIPAddress = async () => {
        try {
            const response = await fetch('https://api.ipify.org?format=json');
            const data = await response.json();
            return data.ip;
        } catch (error) {
            console.error('Ошибка при получении IP-адреса:', error);
            return '0.0.0.0';
        }
    };

    return (
        <div className='loginPage'>
            <div className='loginFrame'>
                <div className='loginData'>
                    <input
                        type='text'
                        placeholder='Токен офиса'
                        className='officeID'
                        value={officeToken}
                        onChange={handleOfficeTokenChange}
                        disabled={showCodeVerify}
                        autoFocus
                    />
                    {showCodeVerify && (
                        <div className='chooseCode'>
                            <div className="chooseCodeMessage">
                                {viewCodeMessage === 'tg' ?
                                    <Icon icon="logos:telegram" width="20px" height="20px" />
                                    :
                                    <Icon icon="flat-color-icons:google" width="20px" height="20px" />
                                }
                                <select
                                    name="currency"
                                    id="currencyChange"
                                    value={viewCodeMessage}
                                    onChange={handleViewCodeMessage}
                                >
                                    <option value="tg">Telegram</option>
                                    <option value="otp">Google</option>
                                </select>
                            </div>
                            <input
                                type='text'
                                placeholder='Код подтверждения'
                                className='codeVerify'
                                value={codeVerify}
                                onChange={handleCodeVerifyChange}
                                autoFocus
                            />
                        </div>
                    )}
                </div>
                <button className='submitData' onClick={handleLoginClick}>
                    {showCodeVerify ? 'Подтвердить' : 'Войти'}
                </button>
            </div>
        </div>
    );
}
