import React from 'react';
import './module.css'
import NameRequisite from '../../../../components/Table/NameRequisite/NameRequisite';
import DeviceComponent from '../../../../components/Table/DeviceComponent/DeviceComponent';
import Balance from '../../../../components/Table/Balance/Balance';
import Status from '../../../../components/Table/Status/Status';
import Limit from '../../../../components/Table/Limit/Limit';
import SuccessfulDeals from '../../../../components/Table/SuccessfulDeals/SuccessfulDeals';



export default function OpenRequisite({ props }) {
    return (
        <div className='openRequisite'>
            {props && props.card && (
                <div>
                    <div className='headerNameRequisite'>
                        Реквизит
                    </div>
                    <div className='bodyDataRequisite'>
                        <div className="headersRequisite">
                            <p className='cardHeader'>Карта</p>
                            <p className='userHeader'>Пользователь</p>
                            <p className='balanceHeader'>Баланс</p>
                            <p className='statusHeader'>Статус</p>
                            <p className='limitHeader'>Лимит</p>
                            <p className='isSuccessDealHeader'>Успешные сделки</p>
                        </div>
                        <div className="rowsRequisite">
                            <div className='cardHeader'>
                                <NameRequisite
                                    fullName={props?.card?.number}
                                    nickname={props?.card?.bank}
                                />
                            </div>
                            <div className='userHeader'>
                                <DeviceComponent
                                    name={props?.card?.name}
                                    uniqDevice={props?.card?.name}
                                />
                            </div>
                            <div className='balanceHeader'>
                                <Balance
                                    balance={props?.card?.balance}
                                />
                            </div>
                            <div className='statusHeader'>
                                {props?.card?.condition ? (
                                    <Status
                                        status={'Активно'}
                                        type={'requisite'}
                                    />
                                ) : (
                                    <Status
                                        status={'Приостановлено'}
                                        type={'requisite'}
                                    />
                                )}
                            </div>
                            <div className='limitHeader'>
                                <Limit
                                    currentSum={props?.card?.day_volume}
                                    limit={props?.card?.day_limit}
                                />
                            </div>
                            <div className='isSuccessDealHeader'>
                                <SuccessfulDeals
                                    successful={props?.card?.deals_approve}
                                    allDeals={props?.card?.deals}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}