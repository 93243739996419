import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import "./module.css";
import Transaction from "../../../../components/Table/Transaction/Transaction";
// import { Icon } from "@iconify/react/dist/iconify.js";
import Status from "../../../../components/Table/Status/Status";
import OpenBasicDevice from "./OpenBasicDevice/OpenBasicDevice";
import OpenTabFilter from "./OpenTabFilter/OpenTabFilter";
import Table from "../../../../components/Table/Table";
import { generateRows } from '../../../../Data';

export default function OpenDeviceGeneral({ data, setPageLoading }) {
    const { id } = useParams();  // Получаем ID из URL
    const [filteredData, setFilteredData] = useState(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const [filterTab, setFilterTab] = useState('deals')
    const [loading, setLoading] = useState(true);
    const [generateData, setGenerateData] = useState([]);
    const [headerTab, setHeaderTab] = useState(data.tables.deals.headers);

    useEffect(() => {
        const fetchData = async () => {
            setPageLoading(true);
            setLoading(true);
            try {
                let url = `/api/device?device_id=${id}`;

                const response = await fetch(url);
                if (response.ok) {
                    const dynamicData = await response.json();
                    console.log(dynamicData);
                    setFilteredData(dynamicData);
                } else {
                    console.error('Ошибка при загрузке данных', response.status);
                }
            } catch (error) {
                console.error('Ошибка:', error);
            } finally {
                setPageLoading(false);
                setLoading(false)
            }
        };

        fetchData();
    }, [id]);

    useEffect(() => {
        if (filterTab === 'deals') {
            if (filteredData?.payments) {
                const formattedData = filteredData.payments.map(item => ({
                    transactionId: item.payment.id,
                    eventTime: item.date.create,
                    transactionState: ['AC', 'FF', 'CF', 'CL'].includes(item.payment.status),
                    transactionSum: item.payment.amount.usdt,
                    transactionSumIsRub: item.payment.amount.rub,
                    transactionBankCard: item.payment.card.number,
                    transactionBankName: item.payment.type,
                    transactionOwnerName: item.payment.device.owner,
                    transactionUniqDevice: item.payment.device.id
                }));
                setHeaderTab(data.tables.deals.headers);
                setGenerateData(formattedData);
            }

        } else if (filterTab === 'dispute') {
            if (filteredData?.disputes) {
                const formattedData = filteredData.disputes.map(item => ({
                    transactionId: item.dispute.id,
                    eventTime: item.dispute.create,
                    transactionState: item.dispute.status,
                    transactionSum: item.payment.amount.usdt,
                    transactionSumIsRub: item.payment.amount.rub,
                    transactionBankCard: item.payment.card.number,
                    transactionBankName: 'sber',
                    transactionOwnerName: item.payment.device.owner,
                    transactionUniqDevice: item.payment.device.id
                }));
                setHeaderTab(data.tables.dispute.headers);
                setGenerateData(formattedData);
            }
        } else if (filterTab ==='message') {
            if (filteredData?.messages) {
                const formattedData = filteredData.messages.map(item => ({
                    date: item.date,
                    from: item.from,
                    text: item.text,
                }));
                setHeaderTab(data.tables.message.headers);
                setGenerateData(formattedData);
            }
        }

    }, [filteredData, filterTab]);


    const handleClick = (index) => {
        setActiveIndex(index);
        switch (index) {
            case 0: 
                setFilterTab('deals');
                break;
            case 1: 
                setFilterTab('dispute');
                break;
            case 2:
                setFilterTab('message');
                break
            default:
                setFilterTab('deals');
        }
    };

    return (
        <div className="OpenDeviceGeneralContainer">
            {loading ? (
                <p style={{ display: 'none' }}>Loading...</p>
            ) :
                filteredData ? (
                    <>
                        <div className="OpenDeviceGeneralHeaderData">
                            <div className="leftHeaderData">
                                <Transaction
                                    eventTime={filteredData?.id}
                                    icon={'mdi:file-document-check-outline'}
                                    style={{ width: '35px', height: '35px' }}
                                    text={filteredData?.name}
                                />
                                <div className="settingsHeaderTransaction">
                                    <Status status={filteredData.status ? 'Активно' : filteredData.last_update === null ? 'Не подключено' : 'Не активно'} type={'device'} />
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="OpenDeviceGeneralBodyData">
                            <OpenBasicDevice data={filteredData} />
                            <OpenTabFilter index={activeIndex} handleClick={handleClick} />
                            {generateData.length > 0 && (
                                <Table
                                    headers={headerTab}
                                    rows={generateRows(generateData, filterTab).map(row => row.components)}
                                />
                            )}
                        </div>
                    </>
                ) : (
                    <p>Нет данных для отображения</p>  // Сообщение, если данные отсутствуют
                )}
        </div>
    );
}
