import React from 'react';
import './module.css';
import { Icon } from '@iconify/react/dist/iconify.js';

export default function OpenReceipt({ props }) {
    // Проверяем, если props пустой массив или не определен
    if (!props || props.length === 0) {
        return null; // Возвращаем null, чтобы ничего не рендерить
    }

    return (
        <div className='openReceipt'>
            {props.map((receiptRow, index) => {
                const { file } = receiptRow;
                const { base64, name, date } = file;
                const [fileType, base64Content] = base64.split(';base64,');
                return (
                    <button
                        key={index}
                        className='receiptLink'
                        onClick={() => {
                            const newWindow = window.open();
                            if (fileType.startsWith('data:image/')) {
                                newWindow.document.write(`
                                    <html>
                                        <head>
                                            <style>
                                                body { display: flex; justify-content: center; align-items: center; height: 100vh; }
                                                img { max-width: 100%; max-height: 100%; }
                                            </style>
                                            <title>fastpay file</title>
                                        </head>
                                        <body>
                                            <img src="${base64}" alt="Фото" />
                                        </body>
                                    </html>
                                `);
                            } else if (fileType === 'data:application/pdf') {
                                newWindow.document.write(`
                                <html>
                                <head><title>fastpay file</title></head>
                                <body>
                                    <iframe src="${base64}" style="width:100%; height:100%;" frameborder="0"></iframe>
                                </body>
                                </html>
                            `);
                            }

                        }}
                    >
                        <div className='receipt'>
                            <Icon
                                icon={'ion:document-outline'}
                                style={{
                                    color: 'black',
                                    backgroundColor: 'rgb(0 72 136 / 10%)',
                                    padding: '8px',
                                    width: '26px',
                                    height: '26px',
                                    borderRadius: '50px'
                                }}
                            />
                            <div className="receiptDescription">
                                <p className="receiptName">{name || 'Неизвестное имя'}</p>
                                <div className="dataNumbers">
                                    <p>{base64 ? `${Math.round(base64.length / 1024)} KB` : 'Размер неизвестен'}</p>
                                    <p>{date || 'Дата неизвестна'}</p>
                                </div>
                            </div>
                        </div>
                    </button>
                );
            })}
            <div className="receiptWrapper">
                {/* <p>Информация о споре</p> */}
                <div className='receiptDate'>
                    <p>Дата принятия</p>
                    <h4>{(props.length > 0 && props[0].file.date) || 'Дата неизвестна'}</h4>
                </div>
            </div>
        </div>
    );
}
