import React from 'react'
import './module.css'
import { Icon } from '@iconify/react/dist/iconify.js'
import { ToastContainer, toast } from 'react-toastify';

export default function Address({ transactionAddress }) {

  const handleCopy = (text) => {
    const textToCopy = text;

    const input = document.createElement('input');
    input.value = textToCopy;
    document.body.appendChild(input);
    input.select();
    document.execCommand('copy');
    document.body.removeChild(input);

    toast.success('Адрес транзакции скопирован в буфер обмена!');
};
  
  return (
    <div className='containerAddress' onClick={() => handleCopy(transactionAddress)}>
        {transactionAddress}
        <ToastContainer/>
        <Icon icon="mi:copy" className='copyIcon'/>
    </div>
  )
}
