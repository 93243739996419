import React, { useState, useEffect } from 'react';
import './module.css';

export default function SearchFilter({ data, onFilterChange, showSumFilter = false}) {
    const [searchTerm, setSearchTerm] = useState('');
    const [filterStatus, setFilterStatus] = useState('all');
    const [filterSum, setFilterSum] = useState('');

    useEffect(() => {
        if (onFilterChange !== 'none') {
            if (searchTerm !== '') {
                onFilterChange(searchTerm, filterStatus, filterSum);
            }
        }
    }, [searchTerm, filterStatus, filterSum, onFilterChange]);

    const handleSearchChange = (event) => {
        const newSearchTerm = event.target.value;
        setSearchTerm(newSearchTerm);
    };

    // const handleStatusChange = (event) => {
    //     const newStatus = event.target.value;
    //     setFilterStatus(newStatus);
    // };

    const handleFilterSum = (event) => {
        const newSum = event.target.value;
        setFilterSum(newSum);
    };

    return (
        <div className='filters'>
            <input
                id='searchFilter'
                className='inputViewFilters'
                placeholder={data[0]}
                value={searchTerm}
                onChange={handleSearchChange}
            />
            {/* {showStatusFilter && onFilterChange !== 'none' && (
                <select
                    id='selectFilter'
                    className='selectviewFilters'
                    value={filterStatus}
                    onChange={handleStatusChange}
                >
                    <option value="all">Все</option>
                    <option value="completed">Завершено</option>
                    <option value="uncompleted">В процессе</option>
                </select>
            )} */}
            {/* {showSumFilter && (
                <input
                    id='inputSum'
                    className='inputSum'
                    placeholder={data[1]}
                    value={filterSum}
                    onChange={handleFilterSum}
                />
            )} */}
        </div>
    );
}
