import React from 'react'
import './module.css'

export default function DeviceComponent({ name, uniqDevice }) {
  return (
    <div className='containerDevice'>
        <p className='nameDevice'>{name}</p>
        <p>{uniqDevice}</p>
      </div>
  )
}
