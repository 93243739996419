import React from 'react'
import { Icon } from '@iconify/react/dist/iconify.js'
import './module.css'

const statusData = {
    finance: [
        {
            backgroundColor: 'rgba(165, 224, 131, 0.501)',
            color: 'rgb(19 91 0)',
            text: 'Завершен'
        },
        {
            backgroundColor: '#ffa1002b',
            color: '#ffa100',
            text: 'В процессе'
        }
    ],
    requisite: [
        {
            backgroundColor: 'rgba(165, 224, 131, 0.501)',
            color: 'rgb(19 91 0)',
            text: 'Активно'
        },
        {
            backgroundColor: '#efefef',
            color: 'rgb(55 55 55)',
            text: 'Приостановлено'
        },
        {
            backgroundColor: '#f0ec8b',
            color: '#59570c',
            text: 'Не подключено'
        },
        {
            backgroundColor: 'rgba(255, 0, 0, 0.17)',
            color: 'red',
            text: 'Заблокировано'
        }
    ],
    device: [
        {
            backgroundColor: 'rgba(165, 224, 131, 0.501)',
            color: 'rgb(19 91 0)',
            text: 'Активно'
        },
        {
            backgroundColor: '#efefef',
            color: 'rgb(55 55 55)',
            text: 'Не активно'
        },
        {
            backgroundColor: 'rgba(255, 0, 0, 0.17)',
            color: 'red',
            text: 'Не подключено'
        }
    ],
};

export default function Status({ status, type }) {
    // Найдём нужный массив статусов в зависимости от типа (category)
    const categoryStatuses = statusData[type] || [];

    // Найдём объект статуса в массиве по тексту (status)
    const currentStatus = categoryStatuses.find((item) => item.text === status);

    // Если статус найден, используем его стили и текст, иначе используем значения по умолчанию
    const backgroundColor = currentStatus ? currentStatus.backgroundColor : '#ffa1002b';
    const color = currentStatus ? currentStatus.color : '#ffa100';
    const text = currentStatus ? currentStatus.text : 'Статус не найден';

    return (
        <div className='containerStatus' style={{ backgroundColor }}>
            <Icon icon="gravity-ui:circle-fill" className='statusIcon' style={{ color }} />
            <p className='statusText' style={{ color }}>
                {text}
            </p>
        </div>
    );
}
