import React, { useState, useEffect } from 'react'
import './module.css'
import { Icon } from '@iconify/react/dist/iconify.js'
import Popup from 'reactjs-popup';

export default function DeleteButton(id) {

    const handleDelete = async (data) => {
        if (data.status === 'Не подключено') {
            try {
                const response = await fetch('/api/detail-full/' + data.id, {
                    method: 'DELETE',
                });

                if (!response.ok) {
                    throw new Error(`Ошибка: ${response.status}`);
                }
                data.setDelete(true);
                console.log('Успешно удален реквизит по id:' + data.id);
            } catch (error) {
                console.error('Ошибка при удалении:', error);
            }
        } else {
            console.log('Статус не позволяет удалить');
        }
    };

    return (
        id.status === 'Не подключено' ?
        <Popup
            trigger={
                <button className="icon-button">
                    <Icon icon="wpf:delete" className='deleteIcon' width="20px" height="20px" />
                </button>
            }
            modal
            closeOnDocumentClick
        >
            {(close) => (
                <div className="popup-content">
                    <p>Вы уверены что хотите удалить данный реквизит?</p>
                    <div className="modalActions">
                        <button className="closeModal" onClick={() => close()}>
                            Нет
                        </button>
                        <button className="deleteReq" onClick={() => {handleDelete(id); close()}}>
                            Да
                        </button>
                    </div>
                </div>
            )}
        </Popup>
        :
        null
    )
}
