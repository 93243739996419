import React, { useState, useEffect } from 'react';
import DateComponent from '../../../components/DateComponent/DateComponent';
import Table from '../../../components/Table/Table';
import SearchFilter from '../../../components/SearchFilter/SearchFilter';
import './module.css';
import { formatDate, MAX_DATE, MIN_DATE, generateRows } from '../../../Data';


export default function Dispute({ data, setPageLoading }) {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [searchData, setSearchData] = useState('');
    const [responseData, setResponseData] = useState([]);
    const [filterStatus, setFilterStatus] = useState('');
    const officeId = localStorage.getItem('office_token');
    const genRows = generateRows(filteredData, 'dispute');
    // console.log(genRows);

    useEffect(() => {
        const fetchData = async () => {
            if ((startDate && !endDate) || (!startDate && endDate)) {
                console.warn("Даты не выбраны. Запрос не будет выполнен.");
                return;
            }

            const sanitizedSearchData = searchData.replace(/\s+/g, ''); // Удаляем все пробелы из номера карты
            if (sanitizedSearchData.length > 0 && sanitizedSearchData.length !== 16) {
                console.warn("Номер карты должен содержать 16 символов.");
                return;
            }
            setPageLoading(true);
            try {
                let url = `/api/disputes?office_id=${officeId}&date_st=${formatDate(startDate)}&date_en=${formatDate(endDate)}`;
                if (filterStatus) {
                    url += `&status=${filterStatus}`;
                }
                if (searchData) {
                    url += `&card=${searchData}`;
                }

                // console.log(filterStatus);
                // console.log(searchData);
                const response = await fetch(url);
                if (response.ok) {
                    const dynamicData = await response.json();
                    setResponseData(dynamicData);

                    let formattedData = [];

                    if (Array.isArray(dynamicData.disputes)) {
                        // Обработка данных как массива объектов
                        formattedData = dynamicData.disputes.map(item => ({
                            transactionId: item.dispute.id,
                            eventTime: item.dispute.create,
                            transactionState: item.dispute.status,
                            transactionReason: item.dispute.reason_id,
                            transactionSum: item.payment.amount.usdt,
                            transactionSumIsRub: item.payment.amount.rub,
                            transactionBankCard: item.payment.card.number,
                            transactionBankName: 'sber',
                            transactionOwnerName: item.payment.device.owner,
                            transactionUniqDevice: item.payment.device.id
                        }));
                    } else if (dynamicData.dispute) {
                        // Обработка данных как объекта
                        formattedData = [
                            {
                                transactionId: dynamicData.dispute.id,
                                eventTime: dynamicData.dispute.create,
                                transactionState: dynamicData.dispute.status,
                                transactionReason: dynamicData.dispute.reason_id,
                                transactionSum: dynamicData.payment.amount.usdt,
                                transactionSumIsRub: dynamicData.payment.amount.rub,
                                transactionBankCard: dynamicData.payment.card.number,
                                transactionBankName: 'sber',
                                transactionOwnerName: dynamicData.payment.device.owner,
                                transactionUniqDevice: dynamicData.payment.device.id
                            }
                        ];
                    } else {
                        console.error('Непредвиденный формат данных:', dynamicData);
                    }

                    setFilteredData(formattedData);
                } else {
                    console.error('Ошибка при загрузке данных', response.status);
                }
            } catch (error) {
                console.error('Ошибка:', error);
            } finally {
                setPageLoading(false);
            }
        };

        fetchData();
    }, [officeId, startDate, endDate, filterStatus, searchData]);

    const handleChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };

    const handleSelectAll = () => {
        setStartDate(MIN_DATE);
        setEndDate(MAX_DATE);
    };

    const handleFilterChange = (searchTerm, filterStatus) => {
        setSearchData(searchTerm.trim());
        setFilterStatus(filterStatus.trim() === 'completed' ? 'accept' : (filterStatus.trim() === 'uncompleted' ? 'reject' : ''));
    };

    return (
        <div className='dispute'>
            <div className="header">
                <h1>Споры</h1>
            </div>
            <div className='headerData'>
                <SearchFilter
                    data={data.tables.dispute.searchInput}
                    onFilterChange={handleFilterChange}
                />
                <DateComponent
                    startDate={startDate}
                    endDate={endDate}
                    handleChange={handleChange}
                    handleSelectAll={handleSelectAll}
                />
            </div>
            <Table
                headers={data.tables.dispute.headers}
                rows={genRows.map(row => row.components)}
                rowHover={'rowHover'}
            />
        </div>
    );
}
