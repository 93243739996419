import './openmodal.css';
import React, { useEffect, useState } from 'react';

export default function OpenModal({ dispute, payment, setDispute, close }) {
    const [state, setState] = useState(0);
    const [reasons, setReasons] = useState([]);
    const [selectedReason, setSelectedReason] = useState(null);
    const [amount, setAmount] = useState(payment.payment.amount.rub);
    
    useEffect(() => {
        const fetchReasons = async () => {
            const response = await fetch('/api/reasons');
            if (response.ok) {
                const data = await response.json();
                setReasons(data);
            }
        }

        fetchReasons();
    }, [])

    const fetchDispute = async (status, paymentStatus) => {
        const body = {
            dispute_status: status,
            dispute_reason: selectedReason,
            payment: payment.payment.id,
            payment_status: paymentStatus,
            payment_amount: amount,
        }
        const response = await fetch('/api/dispute/' + dispute.id, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body)
        })
        if (response.ok) {
            const data = await response.json();
            setDispute(data);
            close(false);
        }
    };

    console.log(selectedReason);
    return (
        <div className="disputeModal">
            {state === 0 && (
                <>
                    <p>Принять платеж на <b>{amount} RUB</b>?</p>
                    <div className='disputeButtons'>
                        <button className='disputeFalse' onClick={() => { setState(payment.payment.status == "SF" ? 2 : 3) }}>
                            Нет
                        </button>
                        <button className='disputeTrue' onClick={() => { setState(4) }}>
                            Да
                        </button>
                    </div>
                </>
            )}
            {state === 4 && (
                <>
                    <p>Сумма пополнения соответсвует <b>{amount} RUB</b>?</p>
                    <div className='disputeButtons'>
                        <button className='disputeFalse' onClick={() => { setState(5) }}>
                            Нет
                        </button>
                        <button className='disputeTrue' onClick={() => { setState(1) }}>
                            Да
                        </button>
                    </div>
                </>
            )}
            {state === 5 && (
                <>
                    <p>Введите точную сумму пололнения в RUB</p>
                    <input 
                        type='number'
                        value={amount}
                        onChange={(event) => (setAmount(event.target.value))}
                    />
                    <div className='disputeButtons'>
                        <button className='disputeFalse' onClick={() => { setState(0) }}>
                            Назад
                        </button>
                        <button className='disputeTrue' onClick={() => { setState(4) }}>
                            Сохранить
                        </button>
                    </div>
                </>
            )}
            {state === 1 && (
                <>
                    <p>Вы уверены, что <b>принимаете</b> платеж на <b>{amount} RUB</b>?</p>
                    <div className='disputeButtons'>
                        <button className='disputeFalse' onClick={() => { setState(0) }}>
                            Нет
                        </button>
                        <button className='disputeTrue' onClick={() => { fetchDispute(true, 'AC') }}>
                            Да
                        </button>
                    </div>
                </>
            )}
            {state === 2 && (
                <>
                    <p>Вы уверены, что хотите <b>отклонить</b> файл пользователя и перейти в чат?</p>
                    <div className='disputeButtons'>
                        <button className='disputeFalse' onClick={() => { setState(0) }}>
                            Нет
                        </button>
                        <button className='disputeTrue' onClick={() => { fetchDispute(false, 'CF') }}>
                            Да
                        </button>
                    </div>
                </>
            )}
            {state === 3 && (
                <>
                    <p>Вы уверены, что хотите <b>отклонить</b> сделку пользователя?</p>
                    <div className='disputeButtons'>
                        <button className='disputeFalse' onClick={() => { setState(0) }}>
                            Нет
                        </button>
                        <button className='disputeTrue' onClick={() => { setState(6) }}>
                            Да
                        </button>
                    </div>
                </>
            )}
            {state === 6 && (
                <>
                    <p>Выберите причину <b>отклонения</b> сделки пользователя </p>
                    <select id='reason' defaultValue="" onChange={(event) => (setSelectedReason(parseInt(event.target.value)))}>
                        <option value="" disabled>Выберите причину</option>
                        {reasons.length > 0 && reasons.map(reason => (
                            <option value={reason.id}>{reason.name}</option>
                        ))}
                    </select>
                    <div className='disputeButtons'>
                        <button className='disputeFalse' onClick={() => { setState(3) }}>
                            Назад
                        </button>
                        <button className='disputeTrue' onClick={() => { fetchDispute(true, 'CL') }}>
                            Сохранить
                        </button>
                    </div>
                </>
            )}
        </div>
    )
}