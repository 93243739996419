import React from 'react'
import { useLocation } from 'react-router-dom'
import './module.css'

export default function Sum({ transactionSum, transactionSumInRub }) {
  function formatNumber(number) {
    return (number !== undefined && number !== null && typeof number === 'number') 
        ? number.toLocaleString('ru-RU', { maximumFractionDigits: 0 })
        : console.log('Недопустимое значение:', number);
}

      let location = useLocation();
      
  return (
    location.pathname === '/finance' ? 
    (
      <div className='sum'>+ {formatNumber(transactionSum)} USDT</div>
    ) : 
    (
      <div className='sum'>
        <p className='sumUSDT'>{formatNumber(transactionSumInRub)} RUB</p>
        {/* <p>{formatNumber(transactionSumInRub)} RUB</p> */}
      </div>
    )
  )
}
