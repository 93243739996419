import React from 'react'
import './module.css'

export default function BankCard({ bankCard, bank }) {

    function formatNumber(number) {
        if (number === undefined) {
            return number;
        }
        
        const cleanedNumber = number.replace(/\D/g, '');
        
        // Проверка на номер банковской карты (16 цифр)
        if (cleanedNumber.length === 16) {
            return cleanedNumber.replace(/(\d{4})(\d{4})(\d{4})(\d{4})/, '$1 $2 $3 $4');
        }

        // Проверка на российский номер телефона (11 цифр), начинающийся с 8
        if (cleanedNumber.length === 11 && cleanedNumber.startsWith('8')) {
            return cleanedNumber.replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, '$1 $2 $3 $4 $5');
        }

        // Проверка на российский номер телефона (11 цифр), начинающийся с +7
        if (cleanedNumber.length === 11 && cleanedNumber.startsWith('7')) {
            return `8 ${cleanedNumber.slice(1).replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, '$1 $2 $3 $4')}`;
        }

        // Проверка на международный номер телефона (длина от 11 до 15 цифр)
        if (cleanedNumber.length >= 11 && cleanedNumber.length <= 15) {
            const countryCodeLength = cleanedNumber.startsWith('7') || cleanedNumber.startsWith('8') ? 1 : cleanedNumber.startsWith('1') ? 1 : 3;
            const localNumber = cleanedNumber.slice(countryCodeLength);
            const formattedLocalNumber = localNumber.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, '$1 $2 $3 $4');
            return `+${cleanedNumber.slice(0, countryCodeLength)} ${formattedLocalNumber}`;
        }
        
        return number; // Если не подходит ни под один из форматов
        }

  return (
    <div className='infoAboutBankCard'>
        <p className='BankCard'>{formatNumber(bankCard)}</p>
        <p>{bank}</p> {/* Определяется на стороне бэка */}
    </div>
  )
}
