import React from 'react'
import './module.css'
import { Icon } from '@iconify/react/dist/iconify.js'

export default function SuccessfulDeals({ successful, allDeals }) {
    const successPercentage = allDeals > 0 ? ((successful / allDeals) * 100).toFixed(0) : 0;

    return (
        <div className='successfulDeals'>
            <div className="successProcent">
                <Icon icon="ep:success-filled" width="14px" height="14px" style={{ color: 'rgb(76 169 0)' }} />
                <span className="success-percentage">{successPercentage} %</span>
            </div>
            <p className="deals-count">
                {successful}/{allDeals}
            </p>
        </div>
    )
}
