import React from 'react'
import './module.css'
import { Icon } from '@iconify/react/dist/iconify.js';

export default function NameRequisite({ fullName, nickname }) {
  return (
    <div className='nameRequisiteContainer'>
        <Icon icon='ion:card-outline' />
        <div className="doTime">
            <p className='do'>{fullName}</p>
            <p className='eventTime'>{nickname}</p>
        </div>
    </div>
  )
}
