import React, { useState, useEffect } from 'react'
import './module.css'
import { Icon } from '@iconify/react/dist/iconify.js';

export default function ExchangeRate({ rate, isMessage }) {
    const [currentDateTime, setCurrentDateTime] = useState('');

    useEffect(() => {
      const intervalId = setInterval(() => {
        const now = new Date();
        const formattedDateTime = now.toLocaleString('ru-RU', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: false,
        }).replace(',', '');
        setCurrentDateTime(formattedDateTime);
      }, 1000);
  
      return () => clearInterval(intervalId);
    }, []);
  return (
    <div>
        <div className="USDTtoRUB">
          <div className="exchangeContainer">
            <p>1 USDT = {rate} ₽</p>
            {isMessage && <Icon icon="line-md:chevron-triple-left" className='changeIcon' width="18px" height="18px"  style={{color: '#0088ff'}} />}
          </div>
            <p className='currentDateTime'>{currentDateTime}</p>
        </div>
    </div>
  )
}
