import React from "react";
import "./module.css"

export default function OpenProfit({ data }) {
    return (
        <>
            <div className="profitContainer">
                <div className="profitDataContainer">
                    <div className="profitCreateDate">
                        <p>Дата создания</p>
                        {data.create}
                    </div>
                    <div className="profitApproved">
                        <p>Прибыль</p>
                        {data.amount_profit} RUB
                    </div>
                    {/* <div className="profitPriceUsdt">
                        <p>Курс USDT</p>
                        { '₽'}
                    </div> */}
                </div>

            </div>
        </>
    );
}