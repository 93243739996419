import React from 'react'
import './module.css'

export default function DeviceState({ battery }) {
    return (
        <div className='deviceState'>
            <div className="battery">
                <div className="poluse"></div>
                <div className="charge">
                    <div className="chargeColumn" style={{display: battery <= 60 && 'none'}}></div>
                    <div className="chargeColumn" style={{display: battery <= 20 && 'none', background: battery <=60 && '#fbff00'}}></div>
                    <div className="chargeColumn" style={{display: battery === 0 && 'none', background: battery <= 20 ? '#ff0000' : battery <= 60 ? '#fbff00' : ''}}></div>
                </div>
            </div>
            <p>
                {battery} %
            </p>
        </div>
    )
}
