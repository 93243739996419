import React from 'react';
import './module.css'

export default function OpenSum({ usdt, rub }) {

    function formatNumber(number, maxDigits) {
        if (typeof number !== 'number') {
            return "0"; // или другое значение по умолчанию, если number не число
        }
        return number.toLocaleString('ru-RU', { maximumFractionDigits: maxDigits });
    }

    return (
        <div className='openSumContainer'>
            {usdt !== undefined && usdt !== null ? (
                <>
                    Сумма спора - {formatNumber(rub, 0)} RUB
                    {/* <div className='SumBottomText'>
                        <p>сумма спора - {formatNumber(rub, 0)} RUB</p>
                        <p>сумма сделки - {formatNumber(rub, 0)} RUB</p>
                    </div> */}
                </>
            ) : (
                <p>Данные отсутствуют</p>
            )}
        </div>
    );
}
