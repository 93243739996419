import React, { useState, useEffect } from 'react';
import DateComponent from '../../../components/DateComponent/DateComponent';
import Table from '../../../components/Table/Table';
import './module.css';
import { formatDate, MAX_DATE, MIN_DATE, generateRows } from '../../../Data';


export default function Finance({ data, setPageLoading }) {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const officeId = localStorage.getItem('office_token');
    const genRows = generateRows(filteredData, 'finance')

    useEffect(() => {
        const fetchData = async () => {
            if ((startDate && !endDate) || (!startDate && endDate)) {
                console.warn("Даты не выбраны. Запрос не будет выполнен.");
                return;
            }

            setPageLoading(true);
            try {
                const response = await fetch(`api/finance?office_id=${officeId}&date_st=${formatDate(startDate)}&date_en=${formatDate(endDate)}`);
                if (response.ok) {
                    const dynamicData = await response.json();

                    const formattedData = dynamicData.map(item => ({
                        eventTime: item.date,
                        transactionState: item.type === 'deposit' ? true : false,
                        transactionSum: item.amount,
                        transactionStatus: item.status === 'end' ? 'Завершен' : 'Выполняется',
                        transactionAddress: item.address
                    }));

                    setFilteredData(formattedData);
                } else {
                    console.error('Ошибка при загрузке данных', response.status);
                }
            } catch (error) {
                console.error('Ошибка:', error);
            } finally {
                setPageLoading(false);
            }
        }
        
        fetchData();
    }, [officeId, startDate, endDate, data]);

    const handleChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };

    const handleSelectAll = () => {
        setStartDate(MIN_DATE);
        setEndDate(MAX_DATE);
    };

    return (
        <div className='finance'>
            <div className="header">
                <h1>Финансы</h1>
                <DateComponent
                    startDate={startDate}
                    endDate={endDate}
                    handleChange={handleChange}
                    handleSelectAll={handleSelectAll}
                />
            </div>
            <Table
                headers={data.tables.finance.headers}
                rows={genRows.map(row => row.components)}
            />
        </div>
    );
}
