import React, { useState, forwardRef } from 'react';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Icon } from '@iconify/react';
import { isSameDay } from 'date-fns';
import { ru } from 'date-fns/locale';
import './module.css';

// Зарегистрируйте русскую локализацию
registerLocale('ru', ru);
// Установите её по умолчанию (по желанию)
setDefaultLocale('ru');

export default function DateComponent({ startDate, endDate, handleChange, handleSelectAll, minDate }) {
  const [isOpen, setIsOpen] = useState(false);
  const nowDate = isSameDay(startDate, new Date());
  const today = 'Сегодня';
  const months = [
    'Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь',
    'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'
  ];

  const handleDateChange = (dates) => {
    const [selectedStart, selectedEnd] = dates;

    if (selectedStart && isSameDay(selectedStart, new Date()) && !selectedEnd) {
      handleChange([new Date(), new Date()]);
    } else {
      handleChange(dates);
    }
  };

  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <button
      className="custom-input"
      onClick={() => setIsOpen(!isOpen)}
      ref={ref}
    >
      <Icon icon="akar-icons:calendar" style={{ width: '20px', height: '20px' }} />
      {nowDate ? today : value || 'Выберите даты'}
    </button>
  ));

  return (
    <div>
      <DatePicker
        dateFormat="dd.MM.yyyy"
        locale="ru"
        selected={startDate}
        startDate={startDate}
        endDate={endDate}
        onChange={handleDateChange}
        selectsRange
        shouldCloseOnSelect={false}
        minDate={minDate}
        maxDate={new Date()} // Ограничиваем выбор дат сегодняшним днем
        customInput={<CustomInput />}
        open={isOpen}
        onClickOutside={() => setIsOpen(false)}
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled
        }) => {
          const currentYear = new Date().getFullYear();
          const selectedYear = date.getFullYear();

          return (
            <div className="custom-header">
              <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled} style={{ height: '26px' }}>
                <Icon icon="weui:arrow-filled" style={{ rotate: '180deg' }} />
              </button>
              <select
                id="month-select"
                name="month"
                value={date.getMonth()}
                onChange={({ target: { value } }) => changeMonth(parseInt(value))}
              >
                {months.slice(0, selectedYear === currentYear ? new Date().getMonth() + 1 : 12).map((month, index) => (
                  <option key={index} value={index}>
                    {month}
                  </option>
                ))}
              </select>
              <select
                id="year-select"
                name="year"
                value={selectedYear}
                onChange={({ target: { value } }) => changeYear(parseInt(value))}
              >
                <option key={currentYear} value={currentYear}>{currentYear}</option>
              </select>
              <button onClick={increaseMonth} disabled={nextMonthButtonDisabled || selectedYear > currentYear || (selectedYear === currentYear && date.getMonth() >= new Date().getMonth())} style={{ height: '26px' }}>
                <Icon icon="weui:arrow-filled" />
              </button>
            </div>
          );
        }}
        calendarContainer={({ className, children }) => (
          <div className={`${className} custom-calendar`}>
            {children}
            <button className="select-all-btn" onClick={handleSelectAll}>
              ВЕСЬ ПЕРИОД
            </button>
          </div>
        )}
      />
    </div>
  );
}
