import React from 'react'
import './module.css'
import { Icon } from '@iconify/react/dist/iconify.js'


export default function Transaction({ eventTime, icon, style, text }) {

  // const doTransaction = true;   

    // Для вывода времени в формате: 26 июня 2024 г. в 15:15

    // const [eventTime, setEventTime] = useState('');
    // const now = new Date();
    // const formattedEventTime = now.toLocaleString('ru-RU', {
    //   day: '2-digit',
    //   month: 'long',
    //   year: 'numeric',
    //   hour: '2-digit',
    //   minute: '2-digit',
    //   hour12: false,
    // }).replace(',', ' в');

    // setEventTime(formattedEventTime);

  return (
    <div className='containerTransaction'>
        <Icon 
        icon={icon} 
        className='icon'
        style={style}
        />
        <div className="doTime">
            <p className="do">{text}</p>
            <p className="eventTime">{eventTime}</p>
        </div>
    </div>
  )
}
