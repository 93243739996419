import React from 'react'
import './module.css'

export default function Limit({ currentSum, limit }) {

    const percentage = Math.min((currentSum / limit) * 100, 100);

    return (
        <div>
            <div className="progress-container">
                <div className="progress-bar" style={{ width: `${percentage}%` }}></div>
            </div>
            <p className="progress-text">
                {currentSum.toLocaleString('ru-RU', { minimumFractionDigits: 2 })} ₽ / <span>{limit.toLocaleString('ru-RU', { minimumFractionDigits: 2 })} ₽</span>
            </p>
        </div>
    )
}
