import React, { useState } from "react";
import "./module.css";
import { Icon } from "@iconify/react/dist/iconify.js";
import Status from "../../../../../components/Table/Status/Status";
import SuccessfulDeals from "../../../../../components/Table/SuccessfulDeals/SuccessfulDeals";
import Verification from "../../../../../components/ModalVerification/Verification";
import Popup from 'reactjs-popup';

export default function OpenBillsOrCards({ data }) {
    const [showModal, setShowModal] = useState(false);
    const [verif, setVerif] = useState(false);
    const [verifCard, setVerifCard] = useState({});

    function formatNumber(number) {
        return number.toLocaleString('ru-RU', { maximumFractionDigits: 2 });
    }

    const openVerification = (card) => {
        setVerifCard(card);
        setShowModal(true);
    }

    const closeVerification = () => {
        setShowModal(false);
    }

    return (
        <div className="billsOrCardsContainer">
            <div className="HeaderName">
                <b>Счета/Карты</b>
            </div>
            {data.additional.map(card => (
                <div className="tableData">
                    <div className="numberTableData">
                        <p>Номер</p>
                        <div className="numberBodyData">
                            <Icon icon="f7:doc-text-fill" />
                            {card.number}
                        </div>
                    </div>
                    <div className="statusTableData">
                        <p>Статус</p>
                        {card.status ?
                            data.card.condition ? (
                                <Status status={'Активно'} type={'requisite'} />
                            ) : (
                                <Status status={'Приостановлено'} type={'requisite'} />
                            ) : (
                                <div className="verifAction" onClick={() => openVerification(card)}>
                                    <Status status={'Не подключено'} type={'requisite'} />
                                </div>
                            )
                        }
                    </div>
                    <div className="turnoverTableData">
                        <p>Баланс</p>
                        {formatNumber(card.balance)} RUB
                    </div>
                    <div className="turnoverTableData">
                        <p>Оборот</p>
                        {formatNumber(card.volume)} RUB
                    </div>
                    <div className="conversionTableData">
                        <p>Конверсия</p>
                        <SuccessfulDeals
                            successful={card.deals_approve}
                            allDeals={card.deals}
                        />
                    </div>
                </div>

            ))}
            <Popup open={showModal} onClose={closeVerification}>
                <Verification
                    card={verifCard}
                    amount={verifCard?.verif_amount}
                    close={closeVerification}
                    setVerif={setVerif}
                    dop={true}
                />
            </Popup>
        </div>
    );
}