import React from 'react'
import { Icon } from '@iconify/react/dist/iconify.js'
import './module.css'

export default function Telegram({ tgId, rate, name }) {

    const logout = async () => {
        const response = await fetch("/api/logout")
        if (response.ok) {
            localStorage.removeItem('office_token');
            localStorage.removeItem('office_type');
            window.location.reload();
        }
    }   

    return (
        <div className="telegram">
            <div className='telegramName'>
                <span>{name}</span>
            </div>
            <div className="telegramId">
                <p>Группа</p>
                <span>{tgId}</span>
            </div>
            <div className="telegramId">
                <p>Ставка офиса</p>
                <span>{rate}%</span>
            </div>
            <div className="telegramId">
                <span>{localStorage.getItem("office_token")}</span>
                <button className="logoutButton" onClick={logout}>
                    <Icon icon="line-md:logout" style={{width: "20px", height: "20px", transition: 'all 150ms ease-in-out'}}/>
                </button>
            </div>
        </div>
    )
}