import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import "./module.css";
import Transaction from "../../../../components/Table/Transaction/Transaction";
import OpenBasicInfo from "./OpenBasicInfo/OpenBasicInfo";
import OpenBillsOrCards from "./OpenBillsOrCards/OpenBillsOrCards";
import OpenDevice from "../../OpenComponents/OpenDevice/OpenDevice";
import Verification from "../../../../components/ModalVerification/Verification";
import Popup from 'reactjs-popup';
import { Icon } from "@iconify/react/dist/iconify.js";
import { ToastContainer, toast } from 'react-toastify';

export default function OpenRequisiteGeneral({ setPageLoading }) {
    const { id } = useParams();  // Получаем ID из URL
    const [filteredData, setFilteredData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [cardCondition, setCardCondition] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [verif, setVerif] = useState(false);
    const navigate = useNavigate();
    const officeId = localStorage.getItem('office_token');

    useEffect(() => {
        const fetchData = async () => {

            setLoading(true);
            setPageLoading(true);
            try {
                let url = `/api/detail-full/${id}`;

                const response = await fetch(url);
                if (response.ok) {
                    const dynamicData = await response.json();
                    setFilteredData(dynamicData);
                    setCardCondition(dynamicData.card.condition);
                    setShowModal(!dynamicData.card.verif);
                    setVerif(dynamicData.card.verif);
                } else {
                    console.error('Ошибка при загрузке данных', response.status);
                }

            } catch (error) {
                console.error('Ошибка:', error);

            } finally {
                setLoading(false);
                setPageLoading(false);
            }
        };

        fetchData();
    }, [id]);

    const changeCondition = async () => {
        const response = await fetch(`/api/detail/${id}`, {
            method: 'PUT',
        });
        if (response.ok) {
            const dynamicData = await response.json();
            setFilteredData(dynamicData);
            setCardCondition(dynamicData.card.condition);
            if (dynamicData.card.condition) {
                toast.success('Карта включена в работу');
            } else {
                toast.error('Карта выключена из работы');
            }
        } else {
            toast.error('Устройство должно быть активно');
        }
    }

    const closeVerification = () => {
        console.log(verif);
        setShowModal(false);
        if (!verif) {
            navigate("/requisite");
        }
    }

    return (
        <div className="openRequisiteContainer">
            {loading ? (
                <p className="loaderContainer">Loading...</p>
            ) : (
                filteredData ? (
                    <>
                        <div className="OpenRequisiteHeaderData">
                            <Transaction
                                eventTime={filteredData.card.name}
                                icon="simple-line-icons:check"
                            />
                            <div className="settingButtons">
                                {cardCondition ? (
                                    <>
                                    <button className="settingStop" onClick={changeCondition}>
                                        <Icon icon="heroicons-outline:pause" width={'20px'} />
                                        Остановить
                                    </button>
                                    </>
                                ) : (
                                    <>
                                    <button className="settingStop" onClick={changeCondition}>
                                        <Icon icon="octicon:play-24" width={'20px'} />
                                        Включить
                                    </button>
                                    
                                    </>
                                )}
                                <ToastContainer />
                                
                            </div>
                        </div>
                        <hr />

                        <Popup open={showModal} onClose={closeVerification}>
                            <Verification 
                            card={filteredData.card} 
                            amount={filteredData.card.verif_amount} 
                            close={closeVerification}
                            setVerif={setVerif}
                            dop={false}
                            />
                        </Popup>

                        <div className="OpenRequisiteBodyData">
                            <OpenBasicInfo data={filteredData} toast={toast}/>
                            <OpenBillsOrCards data={filteredData} />
                            <OpenDevice props={filteredData} />
                        </div>
                    </>
                ) : (
                    <p>Данные не найдены</p>
                )
            )}
        </div>
    );
}


