import React, { useState } from "react";
import "./module.css";
import SuccessfulDeals from "../../../../../components/Table/SuccessfulDeals/SuccessfulDeals";
import { Icon } from "@iconify/react/dist/iconify.js";
// import { ToastContainer, toast } from 'react-toastify';
import Popup from 'reactjs-popup';


export default function OpenBasicInfo({ data, toast }) {

    const [newLimit, setLimit] = useState();
    const [dayLimit, setDayLimit] = useState(data.card.day_limit);

    const handleCopy = (text, type) => {
        const textToCopy = text;

        const input = document.createElement('input');
        input.value = textToCopy;
        document.body.appendChild(input);
        input.select();
        document.execCommand('copy');
        document.body.removeChild(input);

        toast.success(type === 'card' ? 'Номер карты скопирован в буфер обмена!' : type === 'phone' ? 'Номер телефона скопирован в буфер обмена!' : '');
    };

    const handleLimitChange = (e) => {
        let value = e.target.value;

        // Удаляем все, кроме цифр
        value = value.replace(/\D/g, '');

        if (value.length > 6) {
            value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
        } else {
            // Добавляем пробелы после каждой тройки только если цифр меньше 7
            value = value.replace(/(\d{3})(?=\d)/g, '$1 ');
        }


        e.target.value = value;
        setLimit(value);
    };

    const handleSubmit = async (close) => {
        const response = await fetch('/api/detail-full/' + data.card.id, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                day_limit: parseFloat(newLimit.replace(/\D/g, ''))
            })
        })
        if (response.ok) {
            toast.success('Дневной лимит обновлен!');
            setDayLimit(parseFloat(newLimit.replace(/\D/g, '')));
            close();
        }
    }

    function formatNumber(number) {
        return number.toLocaleString('ru-RU', { maximumFractionDigits: 2 });
    }

    return (
        <div className="OpenBasicContainer">
            <div className="firstRowBasicInfo">
                <div className="dayLimitBasic">
                    <p>Суточный лимит</p>
                    <Popup trigger={
                        <button className="dayLimitEdit">
                            <div className="dayLimitBlock">
                                <span>{data.card.day_volume.toLocaleString('ru-RU', { maximumFractionDigits: 2 }) + '/' + dayLimit.toLocaleString('ru-RU', { maximumFractionDigits: 2 }) + ' ₽'}</span>
                                <Icon icon="material-symbols:edit" width="17" height="17" />
                            </div>
                        </button>
                    } modal>
                        {close => (
                            <div className="modal">
                                <div className="modalHeader">Измените суточный лимит</div>
                                <div className="content">
                                    <div className="inputData">
                                        <label htmlFor="dayLimit">Текущий лимит</label>
                                        <input
                                            type="text"
                                            value={dayLimit.toLocaleString('ru-RU', { maximumFractionDigits: 2 } + ' ₽')}
                                            id='dayLimit'
                                            disabled={true}
                                        />
                                    </div>
                                    <div className="inputData">
                                        <label htmlFor="dayLimitNew">Новый лимит</label>
                                        <input
                                            type="text"
                                            id='dayLimitNew'
                                            placeholder='Введите суточный лимит'
                                            onChange={handleLimitChange}
                                            maxLength={11}
                                        />
                                    </div>
                                </div>
                                <div className="modalActions">
                                    <button className="closeModal" onClick={close}>Отмена</button>
                                    <button className="addReqBtn" onClick={() => handleSubmit(close)}>Сохранить</button>
                                </div>
                            </div>
                        )}
                    </Popup>

                </div>
                <div className="conversionBasic">
                    <p>Конверсия</p>
                    <SuccessfulDeals
                        successful={data.card.deals_approve}
                        allDeals={data.card.deals}
                    />
                </div>
                <div className="turnoverBasic">
                    <p>Оборот</p>
                    {data.card.volume} RUB
                </div>
                <div className="profitBasic">
                    <p>Прибыль</p>
                    {formatNumber(data.card.profit)} RUB
                </div>
            </div>
            <hr />
            <div className="secondRowBasicInfo">
                <div className="idBasic">
                    <p>Номер cчета</p>
                    <div className="idBasicData" onClick={() => handleCopy(data.card.payment_number, 'card')}>
                        <p>{data.card.payment_number}</p>
                        <Icon icon="mi:copy" className='copyIcon' />
                        {/* <ToastContainer /> */}
                    </div>
                </div>
                <div className="numberBasic">
                    <p>Номер телефона</p>
                    <div className="numberBasicData" onClick={() => handleCopy(data.card.phone, 'phone')}>
                        <p>{data.card.phone}</p>
                        <Icon icon="mi:copy" className='copyIcon' />
                        {/* <ToastContainer /> */}
                    </div>
                </div>
                <div className="lastNumbersBasic">
                    <p>ФИО</p>
                    {data.card.name}
                </div>
                {/* <div className="paymentLinkBasic">
                    <p>Платежная ссылка</p>
                </div> */}
            </div>
        </div>
    );
}