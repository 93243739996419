import React, { useState, useEffect } from 'react';
import SearchFilter from '../../../components/SearchFilter/SearchFilter';
import Table from '../../../components/Table/Table';
import './module.css';
import { Icon } from '@iconify/react/dist/iconify.js';
import Popup from 'reactjs-popup';
import { generateRows } from '../../../Data';

function AddDevice({ setCreate, create }) {
    const [error, setError] = useState('');
    const [nameDevice, setNameDevice] = useState('');
    const [tokenDevice, setTokenDevice] = useState('');

    const handleSubmit = async () => {
        if (!nameDevice) {
            setError('Введите название')
        } else {
            const body = {
                office_id: localStorage.getItem('office_token'),
                name: nameDevice,
            }
            const response = await fetch("/api/device", {
                method: 'POST',
                body: JSON.stringify(body),
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            if (response.ok) {
                const data = await response.json();
                setTokenDevice(data.token);
                setCreate(true)
            }

        }
    };

    return (
        <Popup trigger={
            <button className="addRequisite">
                <Icon icon="gravity-ui:plus" width="20px" height="20px" style={{ color: 'white' }} />
                <p>Добавить устройство</p>
            </button>
        } modal>
            {close => (
                <div className="modal">
                    <div className="modalHeader">Добавление нового устройства</div>
                    {!create ? (
                        <div className="content">
                            <div className='inputData'>
                                <label>Название устройства</label>
                                <input
                                    type="text"
                                    value={nameDevice}
                                    onChange={(e) => setNameDevice(e.target.value)}
                                    onFocus={() => setError('')}
                                    placeholder="Придуймайте название"
                                />
                                {error && <span style={{ color: error === 'Устройство добавлено!' ? 'rgb(0, 136, 255)' : 'red' }}>{error}</span>}
                            </div>
                        </div>
                    ) : (
                        <div className="content">
                            <div className='inputData'>
                                <label>Название устройства</label>
                                <p>{nameDevice}</p>
                            </div>
                            <div className='inputData'>
                                <label>Токен устройства</label>
                                <p>{tokenDevice}</p>
                            </div>
                        </div>
                    )}
                    <div className="modalActions">
                        {!create ? (
                            <>
                                <button className="closeModal" onClick={close}>Отмена</button>
                                <button className="addReqBtn" onClick={handleSubmit}>Добавить</button>
                            </>
                        ) : (
                            <>
                                <button className="closeModal" onClick={close}>Закрыть</button>
                            </>
                        )}
                    </div>
                </div>
            )}
        </Popup>
    )
}

function StateActivity({ index, handleClick }) {

    return (
        <div className="stateBar">
            <button
                className={`switchState ssall ${index === 0 ? 'active' : ''}`}
                onClick={() => handleClick(0)}
            >
                Все
            </button>
            <button
                className={`switchState ssactive ${index === 1 ? 'active' : ''}`}
                onClick={() => handleClick(1)}
            >
                Активно
            </button>
            <button
                className={`switchState ssnonconnected ${index === 2 ? 'active' : ''}`}
                onClick={() => handleClick(2)}
            >
                Не подключено
            </button>
            <button
                className={`switchState ssnonactive ${index === 3 ? 'active' : ''}`}
                onClick={() => handleClick(3)}
            >
                Не активно
            </button>
        </div>
    )
}

export default function Requisite({ data, setPageLoading }) {
    const [filteredData, setFilteredData] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);
    const [filterStatus, setFilterStatus] = useState('');
    const [searchData, setSearchData] = useState('');
    const officeId = localStorage.getItem('office_token');
    const genRows = generateRows(filteredData, 'device');
    const [create, setCreate] = useState(false);

    useEffect(() => {
        const fetchData = async () => {

            setPageLoading(true);
            try {
                let url = `/api/devices?office_id=${officeId}`;
                if (filterStatus) {
                    url += `&status=${filterStatus}`;
                }
                if (searchData) {
                    url += `&id=${searchData}`;
                }


                const response = await fetch(url);
                if (response.ok) {
                    const dynamicData = await response.json();

                    let formattedData = [];

                    if (Array.isArray(dynamicData)) {
                        formattedData = dynamicData.map(item => ({
                            transactionId: item.token,
                            transactionNickName: item.name,
                            transactionUniqName: 'ID: ' + item.token,
                            transactionStatus: item.status ? 'Активно' : item.last_update ? 'Не активно' : 'Не подключено',
                            transactionLastActive: item.last_update,
                            transactionCountRequisite: item.details,
                            transactionStateDevice: item.battery
                        }));

                    } else if (dynamicData.token) {
                        formattedData = [{
                            transactionId: dynamicData.token,
                            transactionNickName: dynamicData.name,
                            transactionUniqName: 'ID: ' + dynamicData.token,
                            transactionStatus: dynamicData.status ? 'Активно' : dynamicData.last_update ? 'Не активно' : 'Не подключено',
                            transactionLastActive: dynamicData.last_update,
                            transactionCountRequisite: dynamicData.details,
                            transactionStateDevice: dynamicData.battery
                        }]

                    } else {
                        console.error('Неверный формат данных:', dynamicData);
                    }

                    setFilteredData(formattedData);
                } else {
                    console.error('Ошибка при загрузке данных', response.status);
                }

            } catch (error) {
                console.error('Ошибка:', error);

            } finally {
                setPageLoading(false);
            }
        };

        fetchData();
    }, [filterStatus, searchData, data, create])

    const handleClick = (index) => {
        setActiveIndex(index);
        switch (index) {
            case 0: // Все
                setFilterStatus('');
                break;
            case 1: // Активно
                setFilterStatus('active');
                break;
            case 2: // Не подключено
                setFilterStatus('no-status');
                break
            case 3: // Не активно
                setFilterStatus('non-active');
                break;
            default:
                setFilterStatus('');
        }
    };

    const handleFilterChange = (searchTerm) => {
        setSearchData(searchTerm.trim());

    };

    return (
        <div className='device'>
            <div className='headerData'>
                <h1>Устройства</h1>
                <AddDevice setCreate={setCreate} create={create} />
            </div>
            <div className='headerDataFilter'>
                <StateActivity index={activeIndex} handleClick={handleClick} />
                <SearchFilter data={data.tables.device.searchInput} onFilterChange={handleFilterChange} showStatusFilter={false} />
            </div>
            <Table
                headers={data.tables.device.headers}
                rows={genRows.map(row => row.components)}
                rowHover={'rowHover'}
            />
        </div>
    );
}