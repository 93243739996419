import React, { useState, useEffect } from 'react';
import './module.css';
import DateComponent from '../components/DateComponent/DateComponent';
import DataCard from '../components/DataCard/DataCard';
import { formatNumber, time, formatDate, MAX_DATE, MIN_DATE } from '../Data';


function Dashboard({ data, setPageLoading }) {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [dashboardData, setDashboardData] = useState(null);
  const officeId = localStorage.getItem('office_token');

  useEffect(() => {
    const fetchData = async () => {
      if ((startDate && !endDate) || (!startDate && endDate)) {
        console.warn("Даты не выбраны. Запрос не будет выполнен.");
        return;
      }

      setPageLoading(true); // Устанавливаем загрузку на уровне страницы
      try {
        const response = await fetch(`/api/dashboard?office_id=${officeId}&date_st=${formatDate(startDate)}&date_en=${(formatDate(endDate))}`);
        if (response.ok) {
          const dynamicData = await response.json();
          const mergedData = {
            ...data,
            cards: {
              ...data.cards,
              transactionCountAll: {
                ...data.cards.transactionCountAll,
                count: dynamicData.all_count,
              },
              transactionCountFinish: {
                ...data.cards.transactionCountFinish,
                count: dynamicData.accepted_count,
              },
              sum: {
                ...data.cards.sum,
                count: formatNumber(dynamicData.accepted_amount),
              },
              transactionCountInProcess: {
                ...data.cards.transactionCountInProcess,
                count: dynamicData.onpay_count,
              },
              activeDevices: {
                ...data.cards.activeDevices,
                count: dynamicData.device,
              },
              balance: {
                ...data.cards.balance,
                count: formatNumber(dynamicData.office_balance),
              },
              // profit: {
              //   ...data.cards.profit,
              //   count: formatNumber(0),
              // },
              // transactionsConfirmed: {
              //   ...data.cards.transactionsConfirmed,
              //   count: formatNumber(dynamicData.accepted_amount_usdt),
              // },
              timeProcessDispute: {
                ...data.cards.timeProcessDispute,
                count: formatTime(dynamicData.payment_duration),
              },
            },
          };
          setDashboardData(mergedData);
        } else {
          console.error('Ошибка при загрузке данных', response.status);
        }
      } catch (error) {
        console.error('Ошибка:', error);
      } finally {
        setPageLoading(false); // Устанавливаем загрузку на уровне страницы в false
      }
    };

    fetchData();
  }, [officeId, startDate, endDate, data]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
  
    
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(remainingSeconds).padStart(2, '0');
  
    return `${formattedMinutes}:${formattedSeconds}`;
  }
  const handleChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const handleSelectAll = () => {
    setStartDate(MIN_DATE);
    setEndDate(MAX_DATE);
  };

  return (
    <div className='dashboard'>
      <header className='header'>
        <h1>Дашборд - Офис</h1>
        <DateComponent
          startDate={startDate}
          endDate={endDate}
          handleChange={handleChange}
          handleSelectAll={handleSelectAll}
        />
      </header>
      <div className='dataCardsContainer'>
        {dashboardData &&
          Object.keys(dashboardData.cards).map((key) => (
            <DataCard key={key} data={dashboardData.cards[key]} />
          ))}
      </div>
    </div>
  );
}

export default Dashboard;



