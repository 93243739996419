import React from 'react';
import './module.css'
import NameRequisite from '../../../../components/Table/NameRequisite/NameRequisite';
import Status from '../../../../components/Table/Status/Status';
import DeviceState from '../../../../components/DeviceState/DeviceState';


export default function OpenDevice({ props }) {

    const formatDateDevice = (dateString) => {
        const date = new Date(dateString);
        const formattedDate = date.toLocaleString('ru-RU', {
            day: '2-digit',
            month: 'long',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
        }).replace(',', ' в');
        return formattedDate;
    };

    return (
        <div className='openDevice'>
            <div className='headerNameDevice'>
                Устройство
                {/* <button>применить</button> */}
            </div>
            <div className='bodyDataDevice'>
                <div className="headersRequisite">
                    <p className='deviceHeader'>Устройство</p>
                    <p className='statusOfHeader'>Статус</p>
                    <p className='lastActiveHeader'>Последняя активность</p>
                    <p className='requisiteHeader'>Реквизиты</p>
                    <p className='stateHeader'>Состояние</p>
                </div>
                <div className="rowsRequisite">
                    <div className='deviceHeader'>
                        <NameRequisite
                            fullName={props?.device?.name}
                            nickname={'ID: ' + props?.device?.token}
                        />
                    </div>
                    <div className='statusOfHeader'>
                        {props?.device?.status ? (
                            <Status
                                status={'Активно'}
                                type={'device'}
                            />
                        ) : (
                            <Status
                                status={'Не активно'}
                                type={'device'}
                            />

                        )}
                    </div>
                    <div className='lastActiveHeader'>
                        <p><b>{props?.device?.last_update ? formatDateDevice(props?.device?.last_update) : 'Не запущено'}</b></p>
                    </div>
                    <div className='requisiteHeader'>
                        <p><b>{props?.device?.details}</b></p>
                    </div>
                    <div className='stateHeader'>
                        <DeviceState
                            battery={props?.device?.battery}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}