import React from 'react'
import { Icon } from '@iconify/react/dist/iconify.js'
import './module.css'

export default function DataCard({ data }) {
  return (
    <div className='dataCard' style={data.styleCard}>
      <Icon className='icon' icon={data.icon} style={data.styleIcon}/>
      <div className='blockText'>
        <div className="countCurrency">
          <p className='count'>
            {data.count}
          </p>
          {data.currency ?
            <p className='currency'>
              {data.currency} 
            </p>
          : ''}
        </div>
        <p className='nameCard'>{data.nameCard}</p>
      </div>
    </div>
  );
}
